import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const TrackOrder = () => {
  const [orderInfo, setOrderInfo] = useState([]);

   const { trackId } = useParams();
  // const queryParameters = new URLSearchParams(window.location.search);
  // const track_id = queryParameters.get("track-id");

  useEffect(() => {
    // fetch("https://dhobicart.in/store/order-status/" + track_id, {
    // fetch("https://bill.ind.in/track-order/" + track_id, {
    // https://api.dhobicart.in/store/order-status/2408242229070PfRctbh
    fetch("https://api.dhobicart.in/store/order-status/" + trackId, {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setOrderInfo(data);
      });

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  const trackingNo = orderInfo.invoiceNo;
  const bookingDate = orderInfo.createdDate;
  const walletBalance = orderInfo.walletBalance;
  const customerName = orderInfo.customerName;
  const customerMobile = orderInfo.customerMobile;
  const customerAddress = orderInfo.customerAddress;
  const lastModifiedDate = orderInfo.lastModifiedDate;
  const orderNo = orderInfo.orderNo;
  const status = orderInfo.status;
  const itemGarmentCount = orderInfo.itemGarmentCount;
  const deliveredOn = orderInfo.deliveredOn;
  const deliveryOn = orderInfo.deliveryOn;

  const riderName = orderInfo.riderName;
  const riderMobile = orderInfo.riderMobile;

  const bookedBy = orderInfo.bookedBy;
  const storeName = orderInfo.store?.storeName;
  const storeMobileNo = orderInfo.store?.mobileNo;
  const storeAddress = orderInfo.store?.address.fullAddress;
  const brandName = orderInfo.store?.brandName;

  console.log("orderInfo", orderInfo);

  let statusBookedDone = false;
  let statusInProcessDone = false;
  let statusProcessDone = false;
  let statusInWarehouseDone = false;
  let statusOutForDeliveryDone = false;
  let statusDeliveredDone = false;
  let statusUnProcessDone = false;
  let statusCancel = false;
  let statusRunning = false;
  let statusBookedRunning = false;
  let statusInProcessRunning = false;
  let statusProcessRunning = false;

  if (status === "BOOKED") {
    statusBookedDone = true;
  } else if (status === "INPROCESS") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusBookedRunning = true;
  } else if (status === "PROCESSED") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusProcessDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
  } else if (status === "IN_WAREHOUSE") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusInWarehouseDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  } else if (status === "OUT_FOR_DELIVERY") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusProcessDone = true;
    statusOutForDeliveryDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  } else if (status === "UNPROCESSED") {
    statusBookedDone = true;
    statusUnProcessDone = true;
    statusBookedRunning = true;
  } else if (status === "DELIVERED" || status === "DONE") {
    statusBookedDone = true;
    statusDeliveredDone = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
    statusBookedRunning = true;
  } else {
    statusBookedDone = true;
    statusCancel = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  }

  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString?.replace("Z", ""));

    return parsedDate.toLocaleString("en-IN", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour12: true,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            flex: 0.3,
            margin: width < 500 ? "15px" : "30px",
            background: "#fff",
          }}
        >
          <div style={{ marginTop: width < 500 ? "2px" : "30px" }}>
            <div>
              <span
                style={{
                  fontSize: width < 370 ? 9 : width < 500 ? 12 : 14,
                  fontWeight: "700",
                }}
              >
                Bill No.
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 370 ? 14 : width < 500 ? 16 : 18,

                fontWeight: "700",
                marginBottom: "0px !important",
              }}
            >
              {trackingNo}
            </span>
          </div>
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#e3e1dc",
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          ></div>
          <div style={{ marginBottom: width < 500 ? "5px" : "15px" }}>
            {walletBalance > 0 && (
              <div
                style={{
                  marginTop: width < 500 ? "5px" : "15px",
                  marginBottom: width < 500 ? "5px" : "15px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                      fontWeight: "700",
                    }}
                  >
                    Wallet Balance
                  </span>
                </div>
                <span
                  style={{
                    textAlign: "left",
                    color: "#737270",
                    fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                    fontWeight: "700",
                  }}
                >
                  ₹ {walletBalance}
                </span>
              </div>
            )}
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Customer Name
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {customerName}
              </span>
            </div>
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Customer Mobile
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {customerMobile}
              </span>
            </div>
            <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Address
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {customerAddress}
              </span>
            </div>
          </div>
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#e3e1dc",
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          ></div>
          <div style={{ marginBottom: width < 500 ? "10px" : "30px" }}>
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Booked By
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {bookedBy}
              </span>
            </div>
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Store Name
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {storeName}
              </span>
            </div>
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Store Mobile
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {storeMobileNo}
              </span>
            </div>
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                    fontWeight: "700",
                  }}
                >
                  Store Address
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {storeAddress}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 0.7,
            display: "flex",
            flexDirection: "row",
            background: "#fff",
          }}
        >
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#e3e1dc",
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          ></div>
          <div
            style={{
              flex: 1,
              margin: width < 500 ? "10px" : "30px",
              background: "#fff",
            }}
          >
            <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
              <span
                style={{
                  fontSize: width < 370 ? 14 : width < 500 ? 18 : 22,
                  fontWeight: "700",
                }}
              >
                Order Info
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: width < 500 ? "column" : "row",
                justifyContent: width < 500 ? "flex-start" : "space-between",
              }}
            >
              <div
                style={{
                  marginTop: width < 500 ? "5px" : "15px",
                  marginBottom: width < 500 ? "0px" : "15px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                      fontWeight: "700",
                    }}
                  >
                    Order No.
                  </span>
                </div>
                <span
                  style={{
                    textAlign: "left",
                    color: "#737270",
                    fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                    fontWeight: "700",
                  }}
                >
                  {orderNo}
                </span>
              </div>
              <div
                style={{
                  marginTop: width < 500 ? "0px" : "15px",
                  marginBottom: width < 500 ? "5px" : "15px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                      fontWeight: "700",
                    }}
                  >
                    Date
                  </span>
                </div>
                <span
                  style={{
                    textAlign: "left",
                    color: "#737270",
                    fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                    fontWeight: "700",
                  }}
                >
                  {formatDate(bookingDate)}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: width < 500 ? "column" : "row",
                justifyContent: width < 500 ? "flex-start" : "space-between",
              }}
            >
              <div
                style={{
                  marginTop: width < 500 ? "0px" : "15px",
                  marginBottom: width < 500 ? "5px" : "15px",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                      fontWeight: "700",
                    }}
                  >
                    Total Garments
                  </span>
                </div>
                <span
                  style={{
                    textAlign: "left",
                    color: "#737270",
                    fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                    fontWeight: "700",
                  }}
                >
                  {itemGarmentCount}
                </span>
              </div>

              {status === "OUT_FOR_DELIVERY" && riderName !== null && (
                <React.Fragment>
                  <div
                    style={{
                      marginTop: width < 500 ? "0px" : "15px",
                      marginBottom: width < 500 ? "5px" : "15px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "700",
                        }}
                      >
                        Rider Name
                      </span>
                    </div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#737270",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                        fontWeight: "700",
                      }}
                    >
                      {riderName}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: width < 500 ? "0px" : "15px",
                      marginBottom: width < 500 ? "5px" : "15px",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "700",
                        }}
                      >
                        Mobile Number
                      </span>
                    </div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#737270",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 18,

                        fontWeight: "700",
                      }}
                    >
                      {riderMobile}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div
              style={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#e3e1dc",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            ></div>
            <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
              <span
                style={{
                  textAlign: "left",
                  color: "#000",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                  fontWeight: "700",
                }}
              >
                Your Order is
              </span>
              <div>
                <span
                  style={{
                    marginTop: width < 500 ? "5px" : "15px",
                    marginBottom: width < 500 ? "5px" : "15px",
                    fontSize: width < 370 ? 15 : width < 500 ? 20 : 40,
                    fontWeight: "700",
                  }}
                >
                  {status?.replace(/_/g, " ")}
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#000",
                  fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                  fontWeight: "700",
                }}
              >
                as on {formatDate(lastModifiedDate)}
              </span>
            </div>
            <div
              style={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#e3e1dc",
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            ></div>
            <div>
              <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
                <span
                  style={{
                    fontSize: width < 370 ? 12 : width < 500 ? 14 : 18,
                    fontWeight: "700",
                  }}
                >
                  Tracking Order Status
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: width < 500 ? "5px" : "20px",
                  marginBottom: width < 500 ? "5px" : "20px",
                  height: width < 500 ? "60px" : "80px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        //   height: "10px",
                        borderStyle: "solid",
                        borderColor: statusBookedDone ? "#1cd955" : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top:
                          width < 370 ? "10px" : width < 500 ? "20px" : "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: statusBookedRunning
                          ? "#1cd955"
                          : "#cfcccc",
                        left: "8px",
                        top:
                          width < 370 ? "30px" : width < 500 ? "40px" : "50px",
                        position: "absolute",
                        height:
                          width < 370 ? "60px" : width < 500 ? "60px" : "80px",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "20px" : "10px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src="/img/check.png"
                    alt="booked"
                    style={{
                      width:
                        width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    Booked
                  </span>
                  <div>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      We have received your order on {formatDate(bookingDate)}
                    </p>
                  </div>
                </div>
              </div>
              {statusInProcessDone && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: width < 500 ? "60px" : "80px",
                  }}
                >
                  <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          borderWidth: "10px",
                          // height: "10px",
                          borderStyle: "solid",
                          borderColor: statusInProcessDone
                            ? "#1cd955"
                            : "#cfcccc",
                          borderRadius: "30px",
                          position: "absolute",
                          top:
                            width < 370
                              ? "10px"
                              : width < 500
                              ? "20px"
                              : "30px",
                        }}
                      ></div>
                      <div
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: statusInProcessRunning
                            ? "#1cd955"
                            : "#cfcccc",
                          left: "8px",
                          top:
                            width < 370
                              ? "30px"
                              : width < 500
                              ? "40px"
                              : "50px",
                          position: "absolute",
                          height: width < 500 ? "60px" : "80px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "20px" : "10px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src="/img/fulfillment.png"
                      alt="recipt"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "2px" : "10px",
                      marginRight: width < 500 ? "2px" : "10px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        color: "#575656",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                        fontWeight: "700",
                      }}
                    >
                      InProcess
                    </span>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "#6b6a6a",
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "500",
                        }}
                      >
                        Order is InProcess
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {statusProcessDone && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: width < 500 ? "60px" : "80px",
                  }}
                >
                  <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          borderWidth: "10px",
                          // height: "10px",
                          borderStyle: "solid",
                          borderColor: statusProcessDone
                            ? "#1cd955"
                            : "#cfcccc",
                          borderRadius: "30px",
                          position: "absolute",
                          top:
                            width < 370
                              ? "10px"
                              : width < 500
                              ? "20px"
                              : "30px",
                        }}
                      ></div>
                      <div
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: statusProcessRunning
                            ? "#1cd955"
                            : "#cfcccc",
                          left: "8px",
                          top:
                            width < 370
                              ? "30px"
                              : width < 500
                              ? "40px"
                              : "50px",
                          position: "absolute",
                          height: width < 500 ? "60px" : "80px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "20px" : "10px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src="/img/commercial.png"
                      alt="commercial"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "2px" : "10px",
                      marginRight: width < 500 ? "2px" : "10px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        color: "#575656",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                        fontWeight: "700",
                      }}
                    >
                      Processed
                    </span>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "#6b6a6a",
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "500",
                        }}
                      >
                        Order is Processed
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {statusInWarehouseDone && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: width < 500 ? "60px" : "80px",
                  }}
                >
                  <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          borderWidth: "10px",
                          // height: "10px",
                          borderStyle: "solid",
                          borderColor: statusInWarehouseDone
                            ? "#1cd955"
                            : "#cfcccc",
                          borderRadius: "30px",
                          position: "absolute",
                          top:
                            width < 370
                              ? "10px"
                              : width < 500
                              ? "20px"
                              : "30px",
                        }}
                      ></div>
                      <div
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: statusRunning ? "#1cd955" : "#cfcccc",
                          left: "8px",
                          top:
                            width < 370
                              ? "30px"
                              : width < 500
                              ? "40px"
                              : "50px",
                          position: "absolute",
                          height: width < 500 ? "60px" : "80px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "20px" : "10px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src="/img/delivered_1.png"
                      alt="delivered_1"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "2px" : "10px",
                      marginRight: width < 500 ? "2px" : "10px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        color: "#575656",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                        fontWeight: "700",
                      }}
                    >
                      InWarehouse
                    </span>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "#6b6a6a",
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "500",
                        }}
                      >
                        Order is InWarehouse
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {statusOutForDeliveryDone && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "20px",
                    height: width < 500 ? "60px" : "80px",
                  }}
                >
                  <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          borderWidth: "10px",
                          // height: "10px",
                          borderStyle: "solid",
                          borderColor: statusOutForDeliveryDone
                            ? "#1cd955"
                            : "#cfcccc",
                          borderRadius: "30px",
                          position: "absolute",
                          top:
                            width < 370
                              ? "10px"
                              : width < 500
                              ? "20px"
                              : "30px",
                        }}
                      ></div>
                      <div
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor: statusRunning ? "#1cd955" : "#cfcccc",
                          left: "8px",
                          top:
                            width < 370
                              ? "30px"
                              : width < 500
                              ? "40px"
                              : "50px",
                          position: "absolute",
                          height: width < 500 ? "60px" : "80px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "20px" : "10px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src="/img/delivery-boy.png"
                      alt="recipt"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: width < 500 ? "2px" : "10px",
                      marginRight: width < 500 ? "2px" : "10px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        color: "#575656",
                        fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                        fontWeight: "700",
                      }}
                    >
                      Out For Delivery
                    </span>
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          color: "#6b6a6a",
                          fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                          fontWeight: "500",
                        }}
                      >
                        Your Order is Out For Delivery
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        //   height: "10px",
                        borderStyle: "solid",
                        borderColor:
                          statusUnProcessDone || statusCancel
                            ? "red"
                            : statusDeliveredDone
                            ? "#1cd955"
                            : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top:
                          width < 370 ? "10px" : width < 500 ? "20px" : "30px",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "20px" : "10px",
                    marginRight: "10px",
                  }}
                >
                  {statusCancel ? (
                    <img
                      src="/img/cancel-order.png"
                      alt="cancel-order"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  ) : statusUnProcessDone ? (
                    <img
                      src="/img/return.png"
                      alt="return"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  ) : (
                    <img
                      src="/img/delivered.png"
                      alt="delivered"
                      style={{
                        width:
                          width < 370 ? "40px" : width < 500 ? "50px" : "70px",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 370 ? 11 : width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    {status === "CANCEL"
                      ? "CANCEL"
                      : status === "UNPROCESSED"
                      ? "UNPROCESSED"
                      : deliveredOn !== null
                      ? "Delivered"
                      : "Delivery On"}
                  </span>
                  <div>
                    <p
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 370 ? 10 : width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      {status === "CANCEL"
                        ? "Your Order is Canceled On " +
                          formatDate(lastModifiedDate)
                        : status === "UNPROCESSED"
                        ? "Your Order is UnProcessed"
                        : deliveredOn !== null
                        ? "Your Order is Delivered On " +
                          formatDate(deliveredOn)
                        : "Your Order Expected Delivery is on " +
                          formatDate(deliveryOn)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {brandName ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <h2>
              Powered By <span style={{color:"blue"}}>{brandName}</span>
              {/*<Link to="https://www.oneindiatech.com/?page_id=1171">
              Dhobicart
            </Link>*/}
            </h2>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TrackOrder;
