import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import TrackOrder from "../TrackOrder/TrackOrder";
import TrackOrderWithImage from "../TrackOrder/TrackOrderWithImage";

const router = createBrowserRouter(
  createRoutesFromElements(
    // http://localhost:3000/?track-id=100824144146FaJNHD2x
    <Route>
      <Route path="/track-order/:trackId" element={<TrackOrder />} />
      {/*<Route path="/invoice" element={<TrackOrderWithImage />} />*/}
      <Route path="/di/:orderId" element={<TrackOrderWithImage />} />
    </Route>
  )
);

export default router;
