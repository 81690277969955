import React from "react";
import { useParams } from "react-router-dom";

const TrackOrderWithImage = () => {

   const { orderId } = useParams();

  // const queryParameters = new URLSearchParams(window.location.search);
  // const order_id = queryParameters.get("order-id");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img
          // https://api.dhobicart.in/store/di/2408242229070PfRctbh
          // src={"https://bill.ind.in/di/" + order_id}
          // src={"https://api.dhobicart.in/store/di/" + order_id}
          src={"https://api.dhobicart.in/store/di/" + orderId}
          alt="invoice"
        />
      </div>
    </div>
  );
};

export default TrackOrderWithImage;
